<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <el-page-header @back="goBack" content="预约详情"> </el-page-header>
      </div>
      <div style="display: flex; justify-content: space-between">
        <el-card class="box-card" style="width: 30%">
          <div style="display: flex; justify-content: space-between">
            <div>
              <div>订单号：{{ form.lifeOrderNum }}</div>
              <div>服务编号：{{ form.reservationOrderNum }}</div>
              <div>服务名称：{{ form.lifeOrderName }}</div>
              <div>服务人：{{ form.userName }}</div>
              <div>联系电话：{{ form.userPhone }}</div>
              <div>服务地址：{{ form.addressName }}{{ form.serveAddress }}</div>
              <div>用户预约时间：{{ form.reservationTime }}</div>
              <div>协定预约时间：{{ form.agreementTime }}</div>
              <div>实际服务时间：{{ form.serveTime }}</div>
              <div>服务完成时间：{{ form.finishTime }}</div>
              <div>预约备注：{{ form.remark }}</div>

              <div>预约服务协同工作人员编号：{{ form.partnerNum }}</div>
              <div>预约服务协同工作人员：{{ form.partnerName }}</div>
              <div>预约服务协同工作人员电话：{{ form.partnerPhone }}</div>
            </div>
            <div>{{ map[form.isState] }}</div>
          </div>
        </el-card>
        <el-card class="box-card" style="width: 30%">
          <div style="display: flex; justify-content: space-between">
            <div>
              <div>服务机构：{{ form.serveAgencyName }}</div>
              <div>工作人员编号：{{ form.serveNum }}</div>
              <div>工作人员姓名：{{ form.serveUser }}</div>
              <div>工作人员联系方式：{{ form.servePhone }}</div>
              <div>
                服务开始图片：
                <br />
                <div style="display: flex" v-if="form.pictureStartList.length">
                  <img
                    v-for="(item, index) in form.pictureStartList"
                    :key="index"
                    class="bgimg"
                    :src="item.imageStartUrl"
                    alt=""
                    v-if="item.imageStartUrl"
                  />
                </div>
              </div>
              <div>
                服务结束图片：<br />
                <div style="display: flex" v-if="form.pictureEndList.length">
                  <img
                    v-for="(item, index) in form.pictureEndList"
                    :key="index"
                    class="bgimg"
                    :src="item.imageEndUrl"
                    v-if="item.imageEndUrl"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div>修改</div>
          </div>
        </el-card>
        <el-card class="box-card" style="width: 30%">
          <div style="display: flex; justify-content: space-between">
            <div>
              <div>服务评价：</div>
              <div>
                服务态度：<el-rate
                  v-model="form.orderAssessServe"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
              </div>
              <div>
                服务内容：<el-rate
                  v-model="form.orderAssessContent"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
              </div>
              <div>
                服务质量：<el-rate
                  v-model="form.orderAssessQuality"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as bookingApi from '@/api/booking'
export default {
  data() {
    return {
      form: {},
      map: {
        '': '全部',
        0: '服务结束',
        1: '提交预约',
        2: '服务中',
        lifeOrderNum: '',
      },
    }
  },
  mounted() {
    this.lifeOrderNum = this.$route.query.lifeOrderNum
    this.getInfo()
  },
  methods: {
    getInfo() {
      bookingApi
        .queryReservationInfoByNum({
          lifeOrderNum: this.lifeOrderNum,
        })
        .then((res) => {
          this.form = res.data
        })
    },
    goBack() {
      this.$router.push({
        path: '/booking',
        query: {
          // pageN: this.currentPage,
          // id: res.data.data,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bgimg {
  width: 120px;
  height: 120px;
  margin: 10px;
}
</style>
